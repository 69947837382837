.QuoteModule {
  &__container {
    @include media('md-up') {
      padding: 0 2.5625rem;
    }
  }

  &__quote {
    @include media('md-up') {
      max-width: 50rem;
    }
  }
}
