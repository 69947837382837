.CalloutModuleWrapper {
  &__container {
    min-height: 8.1875rem;

    @include media('md-up') {
      min-height: 13.625rem;
    }
  }

  &__text {
    word-break: break-word;

    @include media('md-up') {
      max-width: 11.625rem;
    }
  }

  &__text--wide-space {
    a {
      margin-top: 3rem;
    }
  }

  &__info-section {
    width: 45%;

    @include media('md-up') {
      width: 50%;
    }
  }

  &__info-section--without-text {
    width: 30%;

    @include media('md-up') {
      width: 50%;
    }
  }

  &__info-section--wide {
    width: 75%;

    @include media('md-up') {
      width: 50%;
    }

    .CalloutModuleWrapper__text {
      max-width: 9.625rem;
    }
  }

  &__info-section-image-container {
    width: 70%;
    max-width: 8rem;

    @include media('md-up') {
      width: 10rem;
      max-width: auto;
    }
  }

  &__supporting-section-text--with-image {
    @include media('sm-up') {
      max-width: 40%;
    }

    @include media('md-up') {
      max-width: 11.625rem;
    }
  }

  &__supporting-section-image {
    width: 60%;
    height: 120%;
    object-position: 100% 100%;
    transform: translate3d(10%, 0, 0);

    @include media('md-up') {
      height: 140%;
      transform: translate3d(0, 0, 0);
    }
  }

  &__supporting-section-image--left {
    @include media('md-up') {
      object-position: 100% 0%;
    }
  }

  &__supporting-section-image--without-text {
    width: auto;
  }

  &__supporting-section-image--with-text {
    @include media('xs-down') {
      display: none;
    }
  }

  &__supporting-section {
    width: 55%;

    @include media('md-up') {
      width: 50%;
    }
  }

  &--right,
  &--left {
    .CalloutModuleWrapper__container {
      @include media('md-up') {
        min-height: 44.125rem;
        min-width: 20.25rem;
      }
    }

    .CalloutModuleWrapper__info-section {
      @include media('md-up') {
        min-height: 12.5rem;
        width: 100%;
      }
    }

    .CalloutModuleWrapper__info-section--with-image {
      @include media('md-up') {
        min-height: 10.625rem;
      }
    }

    .CalloutModuleWrapper__supporting-section {
      @include media('md-up') {
        width: 100%;
      }
    }

    .CalloutModuleWrapper__supporting-section-image {
      @include media('md-up') {
        width: 110%;
        height: auto;
      }
    }
  }
}
