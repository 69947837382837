.TextModule {
  &__contents--two-column {
    @include media('md-up') {
      column-count: 2;
      column-gap: 4rem;
    }
  }

  &__image {
    max-height: 9.375rem;
    max-width: 100%;
    margin-bottom: 3rem;

    @include media('sm-up') {
      margin-right: 3rem;
      margin-bottom: 2rem;
    }
  }

  &__contents.text-center {
    .TextModule__image {
      margin: 0 auto 2rem;
    }
  }

  &__text-container {
    @include media('md-up') {
      max-width: 37.5rem;
      margin: 0 auto;
    }
  }

  &__text--two-col-max-width {
    @include media('md-up') {
      max-width: 78rem;
    }
  }

  &__text--max-width {
    @include media('md-up') {
      max-width: 31.25rem;
    }

    @include media('xl-up') {
      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text--full-width {
    @include media('md-up') {
      max-width: unset;
      width: 100%;
    }
  }
}
