.FourColumnVideoModule {
  &__items {
    @include media('sm-up') {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      row-gap: 3.875rem;
      column-gap: 1.5rem;
    }
  }

  &__item {
    min-width: 100%;

    @include media('sm-up') {
      max-width: 16.5rem;
    }

    @include media('xl-up') {
      max-width: 22rem;
    }
  }
}
