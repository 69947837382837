.ArticleUserStory {
  &__story {
    max-height: 100rem;
  }

  &__story--hide-overflow {
    overflow: hidden;
    max-height: 12.5rem;
  }

  &__read-more-button {
    .link-border-bottom-blue {
      &:hover,
      &:focus {
        @extend %link-border-bottom-hidden;
      }
    }
  }

  &__like-icon {
    width: 1.045625rem;
  }

  &__next-previous-button {
    &:hover {
      cursor: pointer;
    }
  }

  &__next-previous-button-container--disabled {
    > .ArticleUserStory__next-previous-button {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
