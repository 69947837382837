.PopUp {
  display: none;
  width: 100%;
  margin-top: $mobile-top-bar-height;
  height: calc(100vh - #{$mobile-top-bar-height});
  padding: 1.5rem 1.8125rem;

  @include media('sm-up') {
    margin-top: $tablet-top-bar-height;
    height: calc(100vh - #{$tablet-top-bar-height});
  }

  &--display {
    display: flex;
  }

  &__bg {
    opacity: 0.6;
  }

  &__container {
    max-width: 30rem;
    padding-bottom: 1.625rem;
    max-height: 34.4375rem;
    height: 80%;

    @include media('md-up') {
      padding-bottom: 2.375rem;
    }
  }

  &__button-container {
    margin-top: 3.5625rem;
  }

  &__decline-button-container {
    margin-top: 1rem;

    @include media('md-up') {
      margin-top: 1.3125rem;
    }
  }
}
