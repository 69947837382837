.JobPostingsModule {
  &__item-arrow {
    margin-top: 0.65rem;

    @include media('md-up') {
      margin-top: 0;
    }
  }

  &__department:first-of-type {
    .JobPostingsModule__department-title {
      margin-top: 0;
    }
  }

  &__item-expandable {
    max-width: 43.75rem;
  }

  &__item-text {
    transition: opacity 300ms;
  }

  &__item-text-expanded {
    transition-delay: 100ms;
  }

  &__description {
    div > span,
    div > b,
    a {
      font-family: $spectral !important;
      font-weight: 400 !important;
      font-size: 1.125rem !important;
      line-height: 1.5625rem !important;
      letter-spacing: -0.025rem !important;
    }
  }
}
