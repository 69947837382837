.ArticleGenericPage {
  &__content {
    max-width: 100%;

    @include media('sm-up') {
      max-width: calc(100% - #{$tablet-article-side-nav-width});
    }
    @include media('md-up') {
      max-width: calc(100% - #{$desktop-article-side-nav-width});
    }
  }
}
