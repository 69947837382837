$z: (
  'below': -1,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  'hero-button': 300,
  'breadcrumbs': 600,
  'pop-up': 650,
  'nav': 700,
  'progress-bar': 2147483647,
  'notification-bar': 750,
  'overlay': 800,
);

@function z($index) {
  @return map-get($z, $index);
}

@each $name, $z-index in $z {
  .z-#{$name},
  %z-#{$name} {
    z-index: #{$z-index};
  }
}
