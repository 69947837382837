.SingleBlockCtaModule {
  max-width: 100%;

  &--underlined:hover,
  &--underlined:focus {
    .SingleBlockCtaModule__text {
      &.color-gray-100,
      &.color-white {
        @extend %link-border-bottom;
      }
    }
  }

  &--with-arrow:hover,
  &--with-arrow:focus {
    .SingleBlockCtaModule__arrow {
      transform: translateX(0.5rem);
    }
  }
}
