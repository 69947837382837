$avatar-height: 2.25rem;
$article-content-width-xl-desktop: 46.875rem;

.ArticleNav {
  &__avatar {
    height: $avatar-height;
    width: $avatar-height;
    min-width: $avatar-height;
  }

  &__content {
    min-height: 100%;
  }

  &__table-of-contents {
    a:first-child span {
      padding-left: 0 !important;
    }

    a:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__table-of-contents-button-container {
    @extend %text-sm;
    padding: 0.5rem;
    padding-left: 0;

    &::marker {
      color: color('blue-50');
    }
  }

  &__table-of-contents-button {
    @extend %text-sm;
  }
}
