@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

// TODO flush this out
@layer base {
  .font-secondary {
    @apply font-serif;
  }

  .font-secondary-small {
    @extend .font-secondary;
    @apply text-[1rem] leading-[140%] tracking-[-0.72px];
  }

  .font-secondary-main {
    @extend .font-secondary;
    @apply text-[1.125rem] leading-[140%] tracking-[-0.72px];
  }

  .font-primary {
    @apply font-sans;
    @apply leading-[120%];
  }

  .font-primary-large {
    @extend .font-primary;
    @apply text-[1.5rem];
  }

  .font-primary-main {
    @extend .font-primary;
    @apply text-[1.125rem];
  }

  .font-primary-smaller {
    @extend .font-primary;
    @apply text-[0.875rem];
  }

  .font-primary-smallest {
    @extend .font-primary;
    @apply text-[0.75rem];
  }
}
