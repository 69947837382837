.ThreeColumnInfoModule {
  &__inner {
    margin: 0 auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    max-width: $card-tile-list-max-width;
  }

  &__info-container {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    gap: 2.5rem;

    @include media('sm-up') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__info-container--manual-content {
    row-gap: 3.875rem;
  }

  &__info-container--two-column {
    grid-template-columns: repeat(2, 1fr);

    @include media('sm-up') {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
