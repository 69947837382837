@import 'tailwind', 'settings', 'config';
@import '~@sanctucompu/basement/src/index';
@import 'sizing', 'fontface', 'typography', 'global';
@import 'components';

*:focus {
  @extend %box-shadow-blue;
  outline: none;
}

[data-whatintent='mouse'] *:focus {
  outline: none;
  box-shadow: none;
}

.App {
  &__page {
    min-height: 100vh;
    width: 100%;
    padding-top: $mobile-top-bar-height;

    @include media('sm-up') {
      padding-top: $tablet-top-bar-height;
    }
  }

  &__page--with-minimal-nav {
    @include media('md-up') {
      width: 100%;
      padding-top: $tablet-top-bar-height;
      margin-left: 0;
    }
  }
}

button {
  // reset margin for Safari
  margin: 0;
}

// nprogress loader
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  @extend %z-progress-bar;

  background: color('blue-80');
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
}

/*
========================================
  START OF COOKIEBOT CONSENT BANNER CSS
========================================
*/
#CybotCookiebotDialog {
  z-index: 2147483647 !important;
}
#CybotCookiebotDialogBodyButtonAccept {
  min-height: 58px !important;
  min-width: 286px !important;
}
@media screen and (min-width: 1280px) {
  #CybotCookiebotDialogBodyButtonAccept {
    min-height: 82px !important;
  }
}
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo,
#CybotCookiebotDialogBodyButtonDecline {
  display: none !important;
}
/*
========================================
  END OF COOKIEBOT CONSENT BANNER CSS
========================================
*/
/*
========================================
  START OF MEDIAVINE CSS
========================================
*/
@media only screen and (min-width: 1200px) {
  .ArticleContent
    > div[class*='style__MainInnerAndSidePanelsContainer']
    > div[class*='style__MainBodyContainer'] {
    max-width: calc(100% - 13.625rem - 440px) !important;
  }

  .ArticleContent
    > div[class*='style__MainInnerAndSidePanelsContainer']
    > div:first-of-type
    > div {
    display: block !important;

    position: sticky !important;

    top: 12rem !important;
  }

  .ArticleContent
    > div[class*='style__MainInnerAndSidePanelsContainer']
    > div:nth-of-type(2) {
    display: block !important;

    min-width: 300px !important;
  }

  .ArticleContent
    > div[class*='style__MainInnerAndSidePanelsContainer']
    > div:nth-of-type(2)
    > div
    > div[class*='style__SymptomCheckerContainer'] {
    height: unset !important;
  }

  #sidebar_btf_placeholder {
    margin-top: 30px !important;
    align-self: flex-start !important;
  }
}

@media only screen and (max-width: 359px) {
  .ArticleContent
    > div[class*='style__MainInnerAndSidePanelsContainer']
    > div[class*='style__MainBodyContainer'] {
    padding-left: 10px !important;

    padding-right: 10px !important;
  }

  ul li .mv-ad-box {
    margin-left: -10px !important;
  }

  #main-content div[class*='BlogPostModuleSwitch__BlogContainer'] .TextModule {
    padding-left: 10px !important;

    padding-right: 10px !important;
  }
}

.video-container.vpaid {
  z-index: 2147483640 !important;
}

div.ym {
  z-index: 2147483640 !important;
}

/*
========================================
  END OF MEDIAVINE CSS
========================================
*/
/*
========================================
  START OF GOOGLEAPIS FONT COPY
========================================
*/
/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8on7mTNmnUHowCw.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8onXmTNmnUHowCw.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8onTmTNmnUHowCw.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8onrmTNmnUHo.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M9knjsS_ulYHs.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M2knjsS_ulYHs.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M3knjsS_ulYHs.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M5knjsS_ul.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtm3FafaPWnIIMrY.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtm3OafaPWnIIMrY.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtm3PafaPWnIIMrY.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtm3BafaPWnII.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*
========================================
  END OF GOOGLEAPIS FONT COPY
========================================
*/
