$transition-duration: 600ms;
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$transition-half: calc(#{$transition-duration} / 2);

@keyframes left-to-right {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.transition-opacity {
  transition: opacity $transition-duration;
}

.transition-short {
  transition: all $transition-half $ease-out-expo;
}

.transition,
%transition {
  transition: all $transition-duration;
}

.transition-underline,
%transition-underline {
  transition: background-size 0.3s $ease-out-expo;
}

.transition-fade-out {
  animation-duration: $transition-duration;
  animation-fill-mode: forwards;
  animation-name: fade-out;
  animation-timing-function: $ease-in-out-quad;
}

.transition-fade-out-short {
  animation-duration: $transition-half;
  animation-fill-mode: forwards;
  animation-name: fade-out;
  animation-timing-function: $ease-in-out-quad;
}

.transition-fade-in {
  animation-duration: $transition-duration;
  animation-fill-mode: forwards;
  animation-name: fade-in;
  animation-timing-function: $ease-in-out-quad;
}

.transition-fade-in-short {
  animation-duration: $transition-half;
  animation-fill-mode: forwards;
  animation-name: fade-in;
  animation-timing-function: $ease-in-out-quad;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.transition-open {
  animation-duration: $transition-duration;
  animation-fill-mode: forwards;
  animation-name: open;
  animation-timing-function: $ease-in-out-quad;
}

.transition-open-short {
  animation-duration: $transition-half;
  animation-fill-mode: forwards;
  animation-name: open;
  animation-timing-function: $ease-in-out-quad;
}

.transition-close {
  animation-duration: $transition-duration;
  animation-fill-mode: forwards;
  animation-name: close;
  animation-timing-function: $ease-in-out-quad;
}

.transition-close-short {
  animation-duration: $transition-half;
  animation-fill-mode: forwards;
  animation-name: close;
  animation-timing-function: $ease-in-out-quad;
}

@keyframes open {
  0% {
    max-height: 0;
    visibility: hidden;
  }

  99% {
    max-height: none;
  }

  100% {
    max-height: none;
  }
}

@keyframes close {
  0% {
    max-height: none;
  }

  99% {
    max-height: 0;
  }

  100% {
    max-height: 0;
    visibility: hidden;
  }
}
