.SplitCtaModule {
  &__title {
    max-width: 47rem;
  }

  &__description {
    max-width: 24rem;
  }

  &--with-right-arrow:hover,
  &--with-right-arrow:focus {
    .SplitCtaModule__arrow {
      transform: translateX(0.5rem);
    }
  }

  &--with-down-arrow:hover,
  &--with-down-arrow:focus {
    .SplitCtaModule__arrow {
      transform: translateY(0.5rem);
    }
  }

  &:hover,
  &:focus {
    .SplitCtaModule__arrow-container.bg-color-blue-lightest {
      background-color: color('blue-10');
    }

    .SplitCtaModule__arrow-container.bg-color-green-lightest {
      background-color: color('teal-30');
    }

    .SplitCtaModule__arrow-container.bg-color-orange-light {
      background-color: color('orange-50');
    }

    .SplitCtaModule__arrow-container.bg-color-orange-lightest {
      background-color: color('orange-10');
    }

    .SplitCtaModule__arrow-container.bg-color-rose-lightest {
      background-color: color('rose-30');
    }

    .SplitCtaModule__arrow-container.bg-color-gray-10 {
      background-color: color('gray-30');
    }
  }
}
