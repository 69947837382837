.ImageCardsModule {
  &__card-image {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    @include media('sm-up') {
      margin-left: -2.25rem;
      margin-right: -2.25rem;
    }
  }
}
