$mobile-top-bar-height: 3.6875rem;
$tablet-top-bar-height: 5.25rem;
$mobile-breadcrumb-height: 3.8125rem;
$desktop-breadcrumb-height: 5.625rem;

// Article General Page
$tablet-article-side-nav-width: 12.5rem;
$desktop-article-side-nav-width: 15rem;

$card-tile-list-max-width: 90rem;
