$spectral: 'Spectral', serif;
$gt-eesti-display: 'GT Eesti Display', sans-serif;

.spectral {
  font-family: $spectral;
}

.gt-eesti-display {
  font-family: $gt-eesti-display;
}

.bold {
  font-weight: 600;
}

.text-xxl,
%text-xxl {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 4.75rem;
  letter-spacing: 0rem;
}

.text-xl-xxl,
%text-xl-xxl {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 3.75rem;
  line-height: 3.9375rem;
  letter-spacing: 0rem;
}

.text-xl,
%text-xl {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.4375rem;
  letter-spacing: 0rem;
}

.text-lg,
%text-lg {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 2.5625rem;
  letter-spacing: 0rem;
}

.text-md-lg,
%text-md-lg {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  letter-spacing: 0rem;
}

.text-md,
%text-md {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0rem;
}

.text-sm,
%text-sm {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  letter-spacing: 0rem;
}

.text-xs,
%text-xs {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0rem;
}

.text-xxs,
%text-xxs {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.9rem;
  letter-spacing: 0rem;
}

.text-link,
%text-link {
  font-family: $gt-eesti-display;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}

.text-secondary-lg {
  font-family: $spectral;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.08rem;
}

.text-secondary-md {
  font-family: $spectral;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.1rem;
  letter-spacing: -0.06rem;
}

.text-secondary-sm,
%text-secondary-sm {
  font-family: $spectral;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  letter-spacing: -0.04rem;
}

.text-secondary-xs {
  font-family: $spectral;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}

.text-article-sm {
  font-size: 0.875rem;
}

.sm-up_text-sm,
%sm-up_text-sm {
  @include media('sm-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.sm-up_text-md-lg,
%sm-up_text-md-lg {
  @include media('sm-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

.sm-up_text-xl-xxl,
%sm-up_text-xl-xxl {
  @include media('sm-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 3.75rem;
    line-height: 4.3125rem;
  }
}

.md-up-text-xxxl,
%md-up-text-xxxl {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 6rem;
    line-height: 6.625rem;
  }
}

.md-up_text-xxl,
%md-up_text-xxl {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 4.75rem;
  }
}

.md-up_text-xl {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 3rem;
    line-height: 3.4375rem;
  }
}

.md-up_text-lg {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 2.5625rem;
  }
}

.md-up_text-md {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.md-up_text-sm,
%md-up_text-sm {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

.md-up_text-xs,
%md-up_text-xs {
  @include media('md-up') {
    font-family: $gt-eesti-display;
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }
}
