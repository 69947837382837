.AuthorPage {
  &__hero {
    @include media('md-up') {
      height: 36rem;
    }
  }

  &__image {
    @include media('md-up') {
      object-position: right center;
    }
  }

  &__practiceName-logo {
    width: auto;
    max-height: 6.5625rem;
    max-width: 100%;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }

  &__educationInfo-logo {
    width: auto;
    max-height: 10rem;
    max-width: 100%;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }

  &__info-title {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  &__carousel-title {
    max-width: 46.875rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include media('sm-up') {
      padding-left: unset;
      padding-right: unset;
    }
  }
}
