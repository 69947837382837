.NumberedListWithImageModule {
  max-width: 85rem;

  &__item-image-container {
    width: 100%;
    max-width: 12.5rem;

    @include media('md-up') {
      max-width: 21.875rem;
    }
  }

  &__item-rich-text,
  &__statistics-list {
    @include media('md-up') {
      max-width: 43.75rem;
    }
  }

  &__statistics-list__item {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 1.125rem;
  }

  &__statistics-list__item-stat {
    font-family: $gt-eesti-display;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;

    @include media('md-up') {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }
}
