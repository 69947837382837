.ArticleGenericPageNav {
  @include media('sm-up') {
    min-width: $tablet-article-side-nav-width;
    width: $tablet-article-side-nav-width;
    margin-top: calc(#{$mobile-breadcrumb-height} * -1);
    position: sticky;
  }

  @include media('md-up') {
    min-width: $desktop-article-side-nav-width;
    width: $desktop-article-side-nav-width;
    margin-top: calc(#{$desktop-breadcrumb-height} * -1);
  }

  &--white-bg {
    border-bottom: $border-gray-lighter;
    border-top: $border-gray-lighter;

    @include media('sm-up') {
      border-right: $border-gray-lighter;
      border-bottom: none;
      border-top: none;
    }
  }

  &__content {
    min-height: 100%;
  }

  &__border {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  &__mobile-content-button {
    &:hover {
      .Button__label,
      .Link__label {
        @extend %link-border-bottom;
      }
    }
  }

  &__content-button {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    &--active {
      font-weight: 600;
    }
  }

  &__underline-button--white {
    .Button__label,
    .Link__label {
      @extend %link-border-bottom-white-hidden;
    }

    &:hover {
      .Button__label,
      .Link__label {
        @extend %link-border-bottom;
      }
    }
  }

  &__underline-button--black {
    .Button__label,
    .Link__label {
      @extend %link-border-bottom-black-hidden;
    }

    &:hover {
      .Button__label,
      .Link__label {
        @extend %link-border-bottom;
      }
    }
  }

  &__underline-button--blue {
    .Button__label,
    .Link__label {
      @extend %link-border-bottom-blue-hidden;
    }

    &:hover {
      .Button__label,
      .Link__label {
        @extend %link-border-bottom;
      }
    }
  }

  &__content-menu--generic-page {
    top: $mobile-top-bar-height;
  }

  &__menu {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    max-height: 0;
  }

  &__menu--active {
    max-height: unset;
  }

  &__arrow-icon {
    min-width: 0.625rem;
  }

  &__dropdown-link-container {
    &:hover {
      @extend %box-shadow-gray;

      .Button__label,
      .Link__label,
      .ArticleGenericPageNav__dropdown-label {
        @extend %link-border-bottom;
      }
    }
  }

  &__dropdown-link-container--black {
    .Button__label,
    .Link__label,
    .ArticleGenericPageNav__dropdown-label {
      @extend %link-border-bottom-black-hidden;
    }
  }

  &__dropdown-link-container--white {
    .Button__label,
    .Link__label,
    .ArticleGenericPageNav__dropdown-label {
      @extend %link-border-bottom-white-hidden;
    }
  }

  &__dropdown-link {
    padding-left: 5.1rem;
  }

  &__dropdown-tag-link {
    padding-left: 6rem;
  }

  &__content-button:first-of-type {
    > div {
      padding-top: 0;
    }
  }
}
