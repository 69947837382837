.TeamListModule {
  &__title-button {
    &:hover {
      .TeamListModule__title {
        @extend %link-border-bottom;
      }
    }
  }

  &__bio {
    cursor: text;
    user-select: text;
  }
}
