$content-hub-md-hero-inner-max-width: 40.625rem;

.PageHero {
  &__container--general-lg {
    padding-top: 5rem;

    @include media('sm-up') {
      padding-top: 10rem;
    }

    .PageHero__module-container {
      // negate padding for hero modules
      margin: 0 -1.5rem;

      @include media('md-up') {
        margin: 0 -2.25rem;
      }
    }
  }

  &__container--content-hub-lg {
    padding-top: 5rem;
    padding-bottom: 9rem;

    @include media('sm-up') {
      padding-top: 6rem;
      padding-bottom: 12rem;
    }
  }

  &__text-container--general-sm {
    padding-top: 5.25rem;
    padding-bottom: 6.25rem;

    @include media('sm-up') {
      padding-top: 10rem;
      padding-bottom: 9.5rem;
    }
  }

  &__container--content-hub-sm {
    padding-top: 5rem;
    padding-bottom: 8.75rem;

    @include media('sm-up') {
      padding-top: 5.8125rem;
      padding-bottom: 10rem;
    }
  }

  &__inner-container--content-hub-md {
    max-width: $content-hub-md-hero-inner-max-width;
    margin: 0 auto;
  }

  &__text-container--general-md {
    margin-top: 2.25rem;
    margin-bottom: 2rem;

    @include media('sm-up') {
      margin-top: 7rem;
      margin-bottom: 7rem;
    }
  }

  &__title--general-lg,
  &__title--content-hub-lg {
    max-width: 50rem;
  }

  &__title--general-sm {
    max-width: 34rem;
  }

  &__description {
    @include media('md-up') {
      max-width: 31.25rem;
    }
  }

  &__button-space {
    height: 7.5rem;

    @include media('sm-up') {
      height: 16.625rem;
    }
  }

  &__button-container-text--content-hub-md {
    @extend %text-xs;

    @include media('sm-up') {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }

  &__img--content-hub-md {
    max-width: 15.1875rem;
    margin-top: 1rem;
  }
}
