/*
  WOFF2 - Super Modern Browsers - first reference always gets loaded first, so we preload this
  WOFF - Pretty Modern Browsers
  OTF - Safari, Android, iOS
*/

@font-face {
  font-family: 'GT Eesti Display';
  src: url('/fonts/GT-Eesti-Display-Regular.woff2') format('woff2'),
    url('/fonts/GT-Eesti-Display-Regular.woff') format('woff'),
    url('/fonts/GT-Eesti-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Display';
  src: url('/fonts/GT-Eesti-Display-Regular-Italic.woff2') format('woff2'),
    url('/fonts/GT-Eesti-Display-Regular-Italic.woff') format('woff'),
    url('/fonts/GT-Eesti-Display-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Eesti Display';
  src: url('/fonts/GT-Eesti-Display-Bold.woff2') format('woff2'),
    url('/fonts/GT-Eesti-Display-Bold.woff') format('woff'),
    url('/fonts/GT-Eesti-Display-Bold.otf') format('opentype');
  font-weight: 600;
  font-display: swap;
}
