.VideoPopUp {
  height: 100vh;
  width: 100%;
  margin-top: $mobile-top-bar-height;
  height: calc(100vh - #{$mobile-top-bar-height});

  @include media('sm-up') {
    padding: 1.5rem 1.8125rem;
    margin-top: $tablet-top-bar-height;
    height: calc(100vh - #{$tablet-top-bar-height});
  }

  &__with-minimal-nav {
    @include media('md-up') {
      width: 100%;
      margin-top: $tablet-top-bar-height;
      height: calc(100vh - #{$tablet-top-bar-height});
    }
  }

  &__container {
    max-width: 46.375rem;

    @include media('sm-up') {
      height: auto;
    }
  }
}
