$border-gray-lighter: 1px solid color('gray-10');

.link-border-bottom,
%link-border-bottom {
  background-size: 100% 1px;
}

.link-border-bottom-black,
%link-border-bottom-black {
  @extend %transition-underline;
  @extend %link-border-bottom;
  background-image: linear-gradient(color('gray-100'), color('gray-100'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-blue,
%link-border-bottom-blue {
  @extend %transition-underline;
  @extend %link-border-bottom;
  background-image: linear-gradient(color('blue-50'), color('blue-50'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-gray-dark,
%link-border-bottom-gray-dark {
  @extend %transition-underline;
  @extend %link-border-bottom;
  background-image: linear-gradient(color('gray-60'), color('gray-60'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-white,
%link-border-bottom-white {
  @extend %transition-underline;
  @extend %link-border-bottom;
  background-image: linear-gradient(color('white'), color('white'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-hidden,
%link-border-bottom-hidden {
  background-size: 0% 1px;
}

.link-border-bottom-blue-hidden,
%link-border-bottom-blue-hidden {
  @extend %transition-underline;
  @extend %link-border-bottom-hidden;
  background-image: linear-gradient(color('blue-50'), color('blue-50'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-gray-hidden,
%link-border-bottom-gray-hidden {
  @extend %transition-underline;
  @extend %link-border-bottom-hidden;
  background-image: linear-gradient(color('gray-50'), color('gray-50'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-white-hidden,
%link-border-bottom-white-hidden {
  @extend %transition-underline;
  @extend %link-border-bottom-hidden;
  background-image: linear-gradient(color('white'), color('white'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-black-hidden,
%link-border-bottom-black-hidden {
  @extend %transition-underline;
  @extend %link-border-bottom-hidden;
  background-image: linear-gradient(color('gray-100'), color('gray-100'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-blue-80-hidden,
%link-border-bottom-blue-80-hidden {
  @extend %transition-underline;
  @extend %link-border-bottom-hidden;
  background-image: linear-gradient(color('blue-80'), color('blue-80'));
  background-repeat: no-repeat;
  background-position: left 90%;
}

.link-border-bottom-appear-on-hover {
  &:hover {
    .link-border-bottom-black-hidden,
    .link-border-bottom-white-hidden,
    .link-border-bottom-gray-hidden,
    .link-border-bottom-blue-hidden,
    .link-border-bottom-blue-80-hidden {
      @extend %link-border-bottom;
    }
  }
}

.module-content-padding-x {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include media('md-up') {
    padding-left: 5.25rem;
    padding-right: 5.25rem;
  }
}

.module-content-padding-with-callout {
  @include media('md-up') {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.border-top-gray-lighter {
  border-top: 1px solid color('gray-10');
}

.border-bottom-gray-lighter {
  border-bottom: 1px solid color('gray-10');
}

.border-top-black-opacity-20 {
  border-top: 1px solid rgba(50, 50, 50, 0.2);
}

.border-bottom-black-opacity-20 {
  border-bottom: 1px solid rgba(50, 50, 50, 0.2);
}

.border-top-gray {
  border-top: 1px solid color('gray-50');
}

.border-bottom-gray {
  border-bottom: 1px solid color('gray-50');
}

.caption-max-width {
  max-width: 30.9375rem;
}

.article-inner-text-full-width,
%article-inner-text-full-width {
  margin-left: -1.5rem;
  min-width: calc(100% + 1.5rem * 2);

  @include media('sm-up') {
    margin-left: -2.25rem;
    min-width: calc(100% + 1.5rem + 2.25rem);
  }
}

.blog-post-inner-text-full-width {
  left: 50%;
  margin: 0 -50vw;
  max-width: 100vw !important;
  position: relative;
  right: 50%;
}

.article-inner-text-full-width-md-up-extra-wide {
  @extend %article-inner-text-full-width;

  @include media('md-up') {
    min-width: calc(120% + 1.5rem + 2.25rem);
  }
}

.box-shadow-blue,
%box-shadow-blue {
  box-shadow:
    0px 0px 4px color('blue-40'),
    inset 0px 0px 4px color('blue-40');
}

.box-shadow-gray,
%box-shadow-gray {
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.scroll-behavior-smooth {
  scroll-behavior: smooth;
}

.remove-transitions * {
  transition: none !important;
}
