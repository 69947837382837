.EmbeddedBuoyAssistant {
  position: fixed;
  z-index: 1000;

  @include media('xs-down') {
    bottom: 1rem;
    right: 1rem;
  }

  @include media('sm-up') {
    bottom: 2rem;
    right: 2rem;
  }

  &__button {
    @extend .EmbeddedBuoyAssistant;
    transition: none;
  }

  &__interview {
    @extend .EmbeddedBuoyAssistant;
    border: 2px solid color('blue-50');
    display: flex;
    flex-direction: column;
    height: 80%;
    max-height: 30rem;

    @include media('xs-down') {
      max-width: 20rem;
      width: 75%;
    }

    @include media('sm-up') {
      width: 20rem;
    }

    &--iframe {
      height: 100%;
    }

    &--top-bar {
      align-items: center;
      background-color: color('blue-50');
      color: white;
      display: flex;
      height: 2.5rem;
      justify-content: flex-end;

      &-close {
        padding-right: 4rem;
      }
    }
  }

  &--hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
