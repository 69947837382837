.EmphasisLinkCardModule {
  &__container {
    max-width: 34.375rem;
  }

  &__container--with-callout {
    @include media('md-up') {
      max-width: 31.25rem;
    }

    @include media('xl-up') {
      max-width: 60rem;
    }
  }
}
